<template>
  <main class="home" v-if="loaded">
    <section class="progress">
      <p class="stage">1</p>
      <div class="bar"><span></span></div>
      <p class="max">3</p>
    </section>
    <section class="header">
      <h1>Tournament details</h1>
      <p>Add and set key details about your tournament (required)</p>
    </section>
    <div class="wrapper">
      <section class="details">
        <div
          :class="{ error: erronousSections.includes('tourname') }"
          id="tourname"
        >
          <h5>Tour name</h5>
          <DefaultTextInput
            :value="tour.name"
            @change="updateName($event)"
            :maxlength="50"
            placeholder="Add tour name (min 3 chars)"
          />
        </div>
      </section>

      <section class="tour-type">
        <h5>Tour type</h5>
        <DefaultRadioButton
          v-for="type in tourTypes"
          :key="type.type"
          :groupname="'tourtype'"
          :value="tour.type"
          :options="{
            title: type.name,
            value: type,
          }"
          @change="$store.dispatch('updateTourType', type)"
        />
        <DefaultRadioButton
          :groupname="'tourtype'"
          :value="tour.type"
          :options="{ title: 'Other', value: null }"
          @change="$store.dispatch('updateTourType', null)"
        />
      </section>

      <section
        class="divisions"
        id="divisions"
        :class="{ error: erronousSections.includes('divisions') }"
      >
        <h5>Divisions</h5>
        <div class="existing-divisions">
          <TheTjingDivisionEditor
            v-for="division in tour.divisions"
            :key="division.type"
            :division="division"
            :tourPassAllowed="true"
            :usedDivisions="usedDivisions"
            :availableDivisions="availableDivisions"
            :builderMode="true"
            :currencies="currencies"
            @updateDivision="updateDivision($event)"
            @deleteDivision="deleteDivision(division.type)"
          />
        </div>
        <div class="new-divisions">
          <TheTjingDivisionEditor
            :tourPassAllowed="true"
            :usedDivisions="usedDivisions"
            :availableDivisions="availableDivisions"
            :addMode="true"
            :builderMode="true"
            :currencies="currencies"
            @addDivision="addDivision($event)"
          />
        </div>
        <p class="mt-4 mb-0">
          Stages and criteria can be added to divisions in the Manager after you
          have saved your tour.
        </p>
      </section>

      <section class="leaderboard">
        <div class="sub-section left">
          <h5>Leaderboard</h5>
          <DefaultRadioButton
            :groupname="'scoringType'"
            :value="tour.scoringType"
            :options="{
              title: 'Combined results',
              value: 'THROWS',
            }"
            @change="$store.dispatch('updateScoringType', 'THROWS')"
          />
          <DefaultRadioButton
            :groupname="'scoringType'"
            :value="tour.scoringType"
            :options="{
              title: 'Average results',
              value: 'AVERAGE_THROWS',
            }"
            @change="$store.dispatch('updateScoringType', 'AVERAGE_THROWS')"
          />
          <DefaultRadioButton
            :groupname="'scoringType'"
            :value="tour.scoringType"
            :options="{
              title: 'Combined pars',
              value: 'PAR',
            }"
            @change="$store.dispatch('updateScoringType', 'PAR')"
          />
          <DefaultRadioButton
            :groupname="'scoringType'"
            :value="tour.scoringType"
            :options="{
              title: 'Points',
              value: 'POINTS',
            }"
            @change="$store.dispatch('updateScoringType', 'POINTS')"
          />
        </div>
        <div class="sub-section auto-open">
          <div class="header">
            <h6>Based on number of best events</h6>
          </div>

          <p>Set how many events should be counted on the tour leaderboard.</p>
          <div class="item-container">
            <select
              class="number-of-events"
              v-model="tour.scoreBasedOnNrOfEvents"
              @change="
                $store.dispatch(
                  'updateScoreBasedOnNumberOfEvents',
                  parseInt($event.target.value) || null
                )
              "
            >
              <option :value="null">All events</option>
              <option :value="n" v-for="n in 52" v-if="n > 1">
                {{ n }}
              </option>
            </select>
          </div>
        </div>
      </section>

      <section class="registration-type">
        <h5>Registration type</h5>
        <DefaultRadioButton
          :groupname="'regtype'"
          :value="tour.registrationMethod"
          :options="{
            title: 'Waiting list',
            value: 'LIST',
            description:
              'Standard registration where players are placed on a waiting list and spots are granted by the TD.',
          }"
          @change="$store.dispatch('updateRegistrationType', 'LIST')"
        />

        <DefaultRadioButton
          :groupname="'regtype'"
          :value="tour.registrationMethod"
          :options="{
            title: 'Groups',
            value: 'GROUPS',
            description:
              'A more casual registration where players choose their own group to play in (note: groups can still be adjusted by the TD).',
          }"
          @change="$store.dispatch('updateRegistrationType', 'GROUPS')"
        />
        <div class="registration-messages">
          <b>Registration notes</b>
          <DefaultToggle
            :value="tour.allowRegistrationMessage"
            @input="$store.dispatch('updateRegistrationMessage', $event)"
          />
          <p>
            Allows players to include a note when registering for an event. Only
            event admins can see notes.
          </p>
        </div>
      </section>

      <section class="nav-buttons">
        <div class="tip">
          <span><Tip /></span>
          <p>
            Everything can be edited in the Manager after your tour has been
            saved.
          </p>
        </div>
        <StandardBorderedButton
          title="Back"
          :fluid="false"
          :backwardsIcon="true"
          :desktopFluidity="true"
          @click="$router.go(-1)"
        />
        <StandardSolidButton
          class="forwards"
          title="Next"
          :fluid="false"
          :forwardIcon="true"
          :desktopFluidity="true"
          @click="validateAndGoNext()"
        />
      </section>
      <section class="start-over" @click="$refs.resetmodal.open()">
        Reset and start over
      </section>
    </div>
    <sweet-modal
      :enable-mobile-fullscreen="false"
      blocking
      ref="resetmodal"
      hide-close-button
    >
      <h3>Reset</h3>
      <p>
        This will remove any information you have added and take you back to the
        start.
      </p>

      <div class="buttons">
        <StandardSolidButton
          title="Cancel"
          :fluid="false"
          @click="$refs.resetmodal.close()"
        />
        <StandardBorderedButton
          class="forwards"
          title="Reset"
          :fluid="false"
          @click="resetAndClose('resetmodal')"
        />
      </div>
    </sweet-modal>
  </main>
</template>

<script>
import TheTjingDivisionEditor from "@/components/TheTjingDivisionEditor";
import DefaultToggle from "@/components/UIElements/DefaultToggle";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import DefaultTextInput from "@/components/UIElements/DefaultTextInput";
import DefaultRadioButton from "@/components/UIElements/DefaultRadioButton";
import Tip from "@/assets/icons/Tip";

export default {
  name: "TournamentDetails",
  data() {
    return {
      loaded: false,
      erronousSections: [],
    };
  },
  components: {
    StandardSolidButton,
    StandardBorderedButton,
    DefaultTextInput,
    DefaultRadioButton,
    Tip,
    TheTjingDivisionEditor,
    DefaultToggle,
  },

  computed: {
    currencies() {
      return this.$store.getters.tour.currencies;
    },
    usedDivisions() {
      let usedDivisions = [];

      this.$store.getters.tour.tour.divisions.forEach((division) => {
        usedDivisions.push(division.type);
      });
      return usedDivisions;
    },
    availableDivisions() {
      return this.$store.getters.tour.divisionTypes;
    },
    globalSettings() {
      return this.$store.getters.tour.oneOffSettings;
    },
    tourTypes() {
      return this.$store.getters.tour.tourTypes;
    },
    tour() {
      return this.$store.getters.tour.tour;
    },
  },
  methods: {
    resetAndClose(modalname) {
      this.$refs[modalname].close();

      this.$store.dispatch("resetBuilderState");
      this.$router.push({ name: "builder" });
    },

    deleteDivision(division) {
      this.$store.dispatch("removeDivision", division);
    },
    updateDivision(division) {
      this.$store.dispatch("updateDivision", division);
    },
    addDivision(division) {
      this.erronousSections = this.erronousSections.filter(
        (error) => error != "divisions"
      );
      this.$store.dispatch("addDivision", division);
    },
    removeError(error) {
      this.erronousSections = this.erronousSections.filter((e) => e != error);
    },
    updateName(data) {
      this.$store.dispatch("updateTourName", data);
      this.removeError("tourname");
    },
    validateAndGoNext() {
      this.markErrors();

      if (this.erronousSections.length > 0) {
        this.$scrollTo("#" + this.erronousSections[0]);
      } else {
        this.$router.push({ name: "tournamentfeatures" });
      }
    },
    markErrors() {
      this.erronousSections = [];

      if (this.tour.name == null || this.tour.name.length < 3) {
        this.erronousSections.push("tourname");
      }
    },
  },
  mounted() {},
  beforeMount() {
    this.loaded = true;
  },
  destroyed() {},
};
</script>


<style lang="scss" scoped>
.start-over {
  margin-top: 64px;
  color: $strom;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  color: $dusk;
}

.leaderboard {
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid $sleet;
  padding: 20px 20px 28px 20px;
  margin-top: 16px;
  border-radius: 6px;

  .item-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
  }

  .default-radio-buttons {
    margin-top: 16px;
  }

  h5 {
    font-size: 16px;
    margin: 0;
  }

  .datepicker {
    margin-top: 12px;
  }

  .auto-open {
    margin-top: 24px;

    .header {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0;

      h6 {
        font-size: 14px;
        margin: 0;
      }

      h5 {
        font-size: 16px;
        @include Gilroy-Bold;
        margin: 0;
      }
    }
    p {
      margin-top: 14px;
      font-size: 14px;
    }
  }
}
.divisions {
  margin-top: 16px;
  background: white;
  padding: 20px 20px 28px 20px;
  border-radius: 6px;
  box-shadow: 0 0 0 1px $sleet;

  &.error {
    box-shadow: 0 0 0 2px $dusk;
  }
  h5 {
    margin-bottom: 0px;
    font-size: 16px;
  }
  .division-editor-container {
    margin-top: 12px;
  }
}
.wrapper {
  padding: 0 15px;
}
.registration-type {
  padding: 20px 20px 28px 20px;
  margin-top: 16px;
  border-radius: 6px;
  border: 1px solid $sleet;
  background: white;
  h5 {
    font-size: 16px;
    margin-bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;

    > div {
      margin-left: 8px;
    }
  }
  .default-radio-buttons {
    margin-top: 16px;
  }
  .registration-messages {
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    b {
      font-size: 14px;
    }
    p {
      margin-top: 12px;
      font-size: 14px;
    }
  }
}
.tour-type {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  background: white;
  padding: 20px 20px 28px 20px;
  margin-top: 24px;
  border: 1px solid $sleet;
  border-radius: 6px;

  h5 {
    width: 100%;
    margin-bottom: 0;
  }
  .default-radio-buttons {
    width: 50%;
    margin-top: 16px;

    &:nth-child(odd) {
      padding-left: 15px;
    }
  }
}
.nav-buttons {
  margin-top: 22px;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .tip {
    padding: 15px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    background: $sunrisemist;
    border: 1px solid #ffd97f;
    border-radius: 6px;
    margin-bottom: 24px;
    width: 100%;
    span {
      margin-right: 10px;
    }

    svg {
      height: 18px;
      width: 14px;
    }

    p {
      display: inline;
      margin: 0;
      font-size: 14px;
    }
  }
  button {
    width: 47%;
  }
}

.details {
  margin-top: 44px;
  span {
    width: 100%;
  }
  > div {
    background: white;
    padding: 20px 20px 28px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 6px;
    box-shadow: 0 0 0 1px $sleet;
    transition: all ease 0.3s;
    &.error {
      box-shadow: 0 0 0 2px $dusk;
    }

    h5 {
      font-size: 16px;
      margin-bottom: 12px;

      span {
        color: $blizzard;
      }

      &.location {
        margin-top: 24px;
      }
    }
  }

  h3 {
    font-size: 24px;
    margin-top: 24px;
  }
  p {
    text-align: center;
    font-size: 16px;
  }
  a {
    font-size: 14px;
    color: $strom;
    margin-top: 8px;
  }

  .default-toggle {
    margin-top: 16px;
  }
  .feature-icon {
    height: 48px;
    width: auto;
  }
}
.progress {
  margin-top: 32px;
  background: none;
  padding: 0 10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }

  .stage {
    font-size: 16px;
    @include Gilroy-Bold;
    margin-right: 12px;
  }
  .max {
    font-size: 16px;
    @include Gilroy-Bold;
    margin-left: 12px;
  }
  .bar {
    width: 100%;
    background: $fog;
    height: 8px;
    border-radius: 4px;

    span {
      width: 33.3%;
      background: $ocean;
      display: flex;
      height: 8px;
      border-radius: 4px;
    }
  }
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 15px;
  h1 {
    font-size: 32px;
    @include Gilroy-Bold;
    text-align: center;
    margin-bottom: 0px;
    margin-top: 24px;
  }
  p {
    font-size: 16px;
    text-align: center;
    margin-top: 16px;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .progress {
    justify-content: center;
    .bar {
      width: 50%;
    }
  }

  .wrapper {
    padding: 0 20px;
  }
}

@media (min-width: 1200px) {
  .start-over {
    font-size: 16px;
  }
  .leaderboard {
    margin-top: 32px;
    border-radius: 10px;
    padding: 40px 48px 52px 40px;
    flex-direction: row;

    .item-container {
      .number-of-events {
        width: 150px;
      }
    }

    .default-radio-buttons {
      margin-top: 32px;
    }
    .left {
      width: 40%;
      border-right: 1px solid $fog;
      padding-right: 48px;
    }
    .datepicker {
      margin-top: 24px;
    }

    .auto-open {
      width: 60%;
      padding-left: 48px;
      margin-top: 0;
      .header {
        h6 {
          font-size: 16px;
        }
      }
      p {
        font-size: 16px;
      }
    }

    h5 {
      font-size: 20px;
    }
  }
  .divisions {
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    padding: 40px 48px 52px 48px;
    margin-top: 32px;
    h5 {
      width: 100%;
      font-size: 20px;
    }

    .existing-divisions,
    .new-divisions {
      width: 50%;
      margin-top: 8px;
      .division-editor-container {
        max-width: unset;
        width: 100%;
      }
    }
    .existing-divisions {
      padding-right: 48px;
    }

    .new-divisions {
      padding-left: 48px;
    }
  }
  .registration-type {
    padding: 40px 48px 52px 40px;
    border-radius: 10px;
    margin-top: 32px;
    h5 {
      font-size: 20px;
    }
    .default-radio-buttons {
      margin-top: 32px;
    }
    .registration-messages {
      margin-top: 32px;
      border-top: 1px solid $fog;
      padding-top: 24px;

      p {
        font-size: 16px;
        width: 100%;
      }
      b {
        font-size: 16px;
      }
    }
  }
  .tour-type {
    padding: 40px 48px 52px 40px;
    border-radius: 10px;
    margin-top: 32px;
    h5 {
      font-size: 20px;
    }
    .default-radio-buttons {
      width: 25%;
      margin-top: 32px;
    }
  }
  .details {
    margin-top: 100px;
    > div {
      display: flex;
      padding: 40px 48px 52px 48px;
      border-radius: 10px;
      h5 {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }

    .default-inputfield {
      width: 50%;
      padding-right: 48px;
    }
  }

  .progress {
    margin: auto;
    margin-top: 92px;
    width: 300px;
    padding: 0;

    .bar {
      width: 100%;
    }
  }
  .header {
    h1 {
      font-size: 64px;
      line-height: 64px;
      margin-top: 58px;
      margin-bottom: 0;
    }
    p {
      font-size: 18px;
      margin-top: 24px;
    }
  }
  .nav-buttons {
    margin-top: 64px;

    .tip {
      width: auto;
      order: 2;
      padding: 0 15px;
      display: flex;
      align-items: center;
      margin: 0;
      p {
        font-size: 16px;
      }
      svg {
        margin-right: 8px;
        margin-bottom: 2px;
      }
    }
    .backward {
      order: 1;
      width: auto;
    }
    .forwards {
      order: 3;
    }
  }
}
</style>
