<template>
  <div class="division-editor-container" :class="{ expanded: expanded }">
    <h6 class="main-title" v-if="!addMode">
      {{ divisionData.name }} ({{ divisionData.type }})
      <span @click="expanded = true" v-if="!expanded">Edit</span>
    </h6>
    <h6
      class="main-title add"
      :class="{ add: addMode }"
      v-if="addMode && !expanded"
      @click="expanded = true"
    >
      Add division
      <Add />
    </h6>
    <div class="section add" v-if="addMode">
      <h6 v-if="expanded">Add division</h6>

      <select class name v-model="newDivision" v-if="expanded">
        <option :value="{}" disabled>Choose division</option>
        <option
          :value="division"
          v-for="division in availableDivisions"
          :key="division.name"
          :disabled="usedDivisions.includes(division.type)"
        >
          {{ division.name }} ({{ division.type }})
        </option>
      </select>
    </div>
    <div class="section">
      <div class="title">
        <h6>Registration fee</h6>
        <p>Optional</p>
      </div>

      <DefaultTextInput
        :invalid="isRegistrationFeeInvalid"
        :placeholder="0"
        :center="true"
        :maxlength="4"
        type="number"
        v-model.number="registrationFee"
      />
      <select name="Currency" class="currency" v-model="divisionData.currency">
        <option :value="currency.code" v-for="currency in enabledCurrencies">
          {{ currency.code }}
        </option>
      </select>
    </div>

    <div class="section margin-top" v-if="tourPassAllowed">
      <div class="title">
        <h6>Tour pass fee</h6>
        <p>Optional</p>
      </div>

      <DefaultTextInput
        :invalid="isTourPassFeeInvalid"
        :placeholder="0"
        :center="true"
        :disabled="!tourPassAllowed"
        :maxlength="4"
        type="number"
        v-model.number="tourPassFee"
      />
      <div class="currency-info">
        <template v-if="divisionData.currency">
          {{ divisionData.currency }}
        </template>
      </div>
      <p class="info" v-if="!builderMode && !tourPassAllowed">
        Tour passes are exclusively available for direct payments only. Enable
        in the
        <router-link
          :to="{
            name: 'tour-overview-settings',
            params: { tourId },
          }"
          >payment section</router-link
        >.
      </p>
      <p class="info" v-else-if="builderMode">
        Tour passes are exclusively available for direct payments only. Enable
        direct payments in the {{ featuresAvailableInStep }}.
      </p>
    </div>
    <div class="section centered">
      <h6 class="fluid">Reserved spots</h6>

      <DefaultTextInput
        :invalid="isReservedSpotsInvalid"
        :placeholder="0"
        :center="true"
        :maxlength="4"
        type="number"
        v-model.number="divisionData.reservedSpots"
      />
    </div>
    <div class="section centered">
      <h6 class="fluid">Max spots</h6>

      <DefaultTextInput
        :invalid="isMaxSpotsInvalid"
        :placeholder="0"
        :center="true"
        :maxlength="4"
        type="number"
        v-model.number="divisionData.maxSpots"
      />
    </div>
    <div class="section buttons">
      <StandardBorderedButton
        type="cancel"
        title="Cancel"
        @click="expanded = false"
      />
      <StandardSolidButton
        type="standard"
        title="Save"
        @click="saveDivision()"
        :disabled="!isDivisionSaveable"
      />
    </div>
    <div
      class="delete deletable"
      v-if="isDivisionDeletable && !addMode"
      @click="deleteDivision()"
    >
      Delete this division
    </div>
  </div>
</template>

<script>
import DefaultTextInput from "@/components/UIElements/DefaultTextInput";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";
import Add from "@/assets/icons/Add";

export default {
  name: "TheTjingDivisionEditor",
  props: [
    "tourId",
    "tourPassAllowed",
    "division",
    "divisions",
    "usedDivisions",
    "addMode",
    "availableDivisions",
    "builderMode",
    "currencies",
  ],
  data() {
    return {
      expanded: false,
      newDivision: {},
      divisionData: {
        id: "",
        type: "",
        name: "",
        registrationFee: {},
        tourPassFee: {},
        reservedSpots: null,
        maxSpots: null,
        isDeletable: false,
        currency: "SEK",
      },
    };
  },
  components: {
    DefaultTextInput,
    StandardBorderedButton,
    StandardSolidButton,
    Add,
  },
  computed: {
    currencyMinimumChargeAmount() {
      return this.$store.state.currencyMinimumChargeAmount;
    },
    enabledCurrencies() {
      return this.currencies.filter(
        (currency) => currency.isEnabledForPayments
      );
    },
    featuresAvailableInStep() {
      let text = "Step 2";

      if (this.$route.name == "leaguessettings") {
        text = "Step 3";
      }
      return text;
    },
    isDivisionDeletable() {
      return this.usedDivisions.length == 1
        ? false
        : this.divisionData.isDeletable;
    },
    isDivisionSaveable() {
      let saveable = false;
      if (
        !this.isTourPassFeeInvalid &&
        !this.isRegistrationFeeInvalid &&
        !this.isReservedSpotsInvalid &&
        !this.isMaxSpotsInvalid &&
        ((this.divisionData.type != "" && this.divisionData.type != null) ||
          this.newDivision.type != null)
      ) {
        saveable = true;
      }

      return saveable;
    },
    isTourPassFeeInvalid() {
      if (
        this.divisionData.tourPassFee?.value != null &&
        this.divisionData.tourPassFee?.value != "" &&
        (this.divisionData.registrationFee?.value == null ||
          this.divisionData.registrationFee?.value == "")
      ) {
        return true;
      }
      if (
        this.divisionData.tourPassFee == null ||
        this.divisionData.tourPassFee == ""
      ) {
        return false;
      }
      if (
        this.divisionData.tourPassFee.value == null ||
        this.divisionData.tourPassFee.value == ""
      ) {
        return false;
      }
      if (
        !isNaN(this.divisionData.tourPassFee.value) &&
        this.divisionData.tourPassFee.value >= 10 &&
        this.divisionData.tourPassFee.value <= 9999
      ) {
        return false;
      }
      return true;
    },
    isRegistrationFeeInvalid() {
      if (
        this.divisionData.registrationFee == null ||
        this.divisionData.registrationFee == ""
      ) {
        return false;
      }
      if (
        this.divisionData.registrationFee.value == null ||
        this.divisionData.registrationFee.value == ""
      ) {
        return false;
      }
      if (
        this.divisionData.registrationFee.value <
        this.currencyMinimumChargeAmount[this.divisionData.currency]
      ) {
        console.log(
          this.currencyMinimumChargeAmount[this.divisionData.currency]
        );
        return true;
      }
      if (
        !isNaN(this.divisionData.registrationFee.value) &&
        this.divisionData.registrationFee.value >= 0 &&
        this.divisionData.registrationFee.value <= 9999
      ) {
        return false;
      }

      return true;
    },
    isMaxSpotsInvalid() {
      if (this.divisionData.maxSpots == null) {
        return false;
      }
      if (
        !isNaN(this.divisionData.maxSpots) &&
        this.divisionData.maxSpots >= 0 &&
        this.divisionData.maxSpots <= 9999
      ) {
        return false;
      }
      return true;
    },
    isReservedSpotsInvalid() {
      if (this.divisionData.reservedSpots == null) {
        return false;
      }
      if (
        !isNaN(this.divisionData.reservedSpots) &&
        this.divisionData.reservedSpots >= 0 &&
        this.divisionData.reservedSpots <= 9999
      ) {
        return false;
      }
      return true;
    },
    registrationFee: {
      get: function () {
        if (
          this.divisionData.registrationFee &&
          this.divisionData.registrationFee.value
        ) {
          return this.divisionData.registrationFee.value;
        } else {
          return null;
        }
      },

      set: function (newValue) {
        this.divisionData.registrationFee = {
          value: newValue,
        };
      },
    },
    tourPassFee: {
      get: function () {
        if (
          this.divisionData.tourPassFee &&
          this.divisionData.tourPassFee.value
        ) {
          return this.divisionData.tourPassFee.value;
        } else {
          return null;
        }
      },

      set: function (newValue) {
        this.divisionData.tourPassFee = {
          value: newValue,
        };
      },
    },
  },
  methods: {
    resetDivision() {
      this.divisionData.name = null;
      this.divisionData.type = null;
      this.divisionData.reservedSpots = null;
      this.divisionData.maxSpots = null;
      this.divisionData.registrationFee.value = null;
      this.divisionData.tourPassFee.value = null;
      this.divisionData.isDeletable = true;
    },
    async deleteDivision() {
      if (this.builderMode) {
        this.$emit("deleteDivision");
      } else {
        try {
          const deletedDivision = await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
                mutation{
                  DeleteDivision(divisionId:"${this.divisionData.id}")
                }
              `,
            },
          });

          if (deletedDivision.data.errors) {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Division can't be deleted",
              type: "error",
            });
          } else {
            this.$store.dispatch("showMessage", {
              show: true,
              message: "Saved",
              type: "success",
            });
          }

          this.expanded = false;
          this.$parent.loadData();
        } catch (err) {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
          });
        }
      }
    },
    addDivision() {
      let registrationFee = 0;
      let reservedSpots;
      let maxSpots;
      let tourPassFee;
      let currencyCode = this.divisionData.currency;

      if (this.divisionData.registrationFee?.value) {
        registrationFee = this.divisionData.registrationFee.value;
      }

      if (
        this.divisionData.reservedSpots == null ||
        this.divisionData.reservedSpots == ""
      ) {
        reservedSpots = null;
      } else {
        reservedSpots = this.divisionData.reservedSpots;
      }
      if (
        this.divisionData.maxSpots == null ||
        this.divisionData.maxSpots == ""
      ) {
        maxSpots = null;
      } else {
        maxSpots = this.divisionData.maxSpots;
      }

      if (
        this.divisionData.tourPassFee == null ||
        this.divisionData.tourPassFee == ""
      ) {
        tourPassFee = null;
      } else if (this.divisionData.tourPassFee.value) {
        tourPassFee = this.divisionData.tourPassFee.value;
      }

      this.$axios({
        headers: { Authorization: this.$store.state.user.sessionInfo.token },
        data: {
          query: `
          mutation CreateDivision($CreateDivision: DivisionInput!){
            CreateDivision(tourId:"${this.tourId}",input:$CreateDivision, asRole:TOUR_ADMIN){
              id
            }
          }
          `,
          variables: {
            CreateDivision: {
              type: this.newDivision.type,
              reservedSpots: reservedSpots,
              maxSpots: maxSpots,
              registrationFee: registrationFee,
              tourPassFee: tourPassFee,
              currencyCode,
            },
          },
        },
      })
        .then(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Saved",
            type: "success",
          });
          this.expanded = false;
          this.newDivision = {};
          this.resetDivision();
          this.$parent.loadData();
        })
        .catch(() => {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
          });
        });
    },
    async saveDivision() {
      if (this.builderMode && this.isDivisionSaveable) {
        if (this.addMode) {
          let divisionToAdd = {
            currency: this.divisionData.currency,
            type: this.newDivision.type,
            name: this.newDivision.name,
            reservedSpots: this.divisionData.reservedSpots,
            maxSpots: this.divisionData.maxSpots,
            registrationFee: {
              value: this.divisionData.registrationFee.value,
              currencyCode: this.divisionData.currency,
            },
            tourPassFee: { value: this.divisionData.tourPassFee.value },
            isDeletable: true,
          };

          this.$emit("addDivision", divisionToAdd);
          this.newDivision = {};
          this.expanded = false;
        } else {
          this.$emit("updateDivision", this.divisionData);
          this.expanded = false;
        }
      } else if (this.addMode && this.isDivisionSaveable) {
        await this.addDivision();
      } else if (this.isDivisionSaveable) {
        let registrationFee = 0;
        let reservedSpots;
        let maxSpots;
        let tourPassFee;
        let currencyCode = this.divisionData.currency;

        if (this.divisionData.registrationFee?.value) {
          registrationFee = this.divisionData.registrationFee.value;
        }

        if (
          this.divisionData.reservedSpots == null ||
          this.divisionData.reservedSpots == ""
        ) {
          reservedSpots = null;
        } else {
          reservedSpots = this.divisionData.reservedSpots;
        }

        if (
          this.divisionData.maxSpots == null ||
          this.divisionData.maxSpots == ""
        ) {
          maxSpots = null;
        } else {
          maxSpots = this.divisionData.maxSpots;
        }
        if (
          this.divisionData.tourPassFee == null ||
          this.divisionData.tourPassFee == ""
        ) {
          tourPassFee = null;
        } else if (this.divisionData.tourPassFee.value) {
          tourPassFee = this.divisionData.tourPassFee.value;
        }

        try {
          let updatedDivision = await this.$axios({
            headers: {
              Authorization: this.$store.state.user.sessionInfo.token,
            },
            data: {
              query: `
          mutation UpdateDivision($UpdateDivision: UpdateDivisionInput!){
            UpdateDivision(divisionId:"${this.division.id}",input:$UpdateDivision, asRole:TOUR_ADMIN){
              id
            }
          }
          `,
              variables: {
                UpdateDivision: {
                  reservedSpots: reservedSpots,
                  maxSpots: maxSpots,
                  registrationFee: registrationFee,
                  tourPassFee: tourPassFee,
                  currencyCode,
                },
              },
            },
          });
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Updated",
            type: "success",
          });
          setTimeout(() => {
            this.$parent.loadData();
            this.expanded = false;
          }, 500);
        } catch (err) {
          this.$store.dispatch("showMessage", {
            show: true,
            message: "Error",
            type: "error",
          });
        }
      }
    },
  },
  beforeMount() {
    if (this.division) {
      this.divisionData.id = this.division.id;
      this.divisionData.name = this.division.name;
      this.divisionData.type = this.division.type;
      this.divisionData.reservedSpots = this.division.reservedSpots;
      this.divisionData.maxSpots = this.division.maxSpots;
      this.divisionData.registrationFee = this.division.registrationFee;
      this.divisionData.tourPassFee = this.division.tourPassFee;
      this.divisionData.isDeletable = this.division.isDeletable;

      if (this.division.currency) {
        this.divisionData.currency = this.division.currency;
      } else {
        this.divisionData.currency = this.division.registrationFee
          ? this.division.registrationFee.currency
          : "SEK";
      }
    } else {
      this.resetDivision();
    }
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
h6 {
  font-size: 14px;
  @include Gilroy-Bold;
  display: flex;
  justify-content: space-between;

  span {
    color: $twilight;
    @include Gilroy-Medium;
    cursor: pointer;
  }

  &.main-title {
    height: 48px;
    height: 48px;
    align-items: center;
    padding: 0 16px;
    margin-bottom: 0;
    font-size: 16px;
  }

  &.add {
    color: $blizzard;
    @include Gilroy-Medium;
  }
}

.division-editor-container {
  box-shadow: 0 0 0 1px $blizzard;
  border-radius: 6px;
  box-sizing: content-box;
  transition: all ease 0.3s;

  &.expanded {
    box-shadow: 0 0 0 2px $ocean;

    .main-title {
      height: 24px;
      padding-top: 12px;
    }

    .section {
      max-height: 220px;
      overflow: auto;
      padding: 20px 16px 28px 16px;
      transition: all ease 0.3s;
      flex-wrap: wrap;
      justify-content: flex-start;

      &.centered {
        align-items: center;
        justify-content: space-between;
      }

      .title {
        width: 100%;
        margin-bottom: 12px;
      }

      h6 {
        width: 100%;
        margin: 0;

        &.fluid {
          width: auto;
        }
      }

      .default-inputfield {
        width: 47%;
      }

      .currency-info {
        width: 47%;
        border: 1px solid $blizzard;
        border-radius: 6px;
        margin-left: 15px;
        background: $mist;
        color: $strom;
        display: flex;
        align-items: center;
        justify-content: center;
        @include Gilroy-Bold;
      }

      .currency {
        width: 47%;
        margin: 0;
        margin-left: 15px;
        option {
          width: 80%;
        }
      }

      &.add {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        select {
          width: 100%;
          border: 1px solid $sleet;
          background-color: transparent;
          margin-top: 12px;
          margin-bottom: 0;
        }
        .main-title {
          .title {
            width: 35%;
          }
        }
      }
    }

    .delete {
      max-height: 100px;
      overflow: auto;
      padding: 15px 0 28px 0;
    }
  }

  .main-title {
    &.add {
      cursor: pointer;
      span {
        box-sizing: content-box;
        color: $teal;
        height: 21px;
        width: 21px;
        display: flex;
        border-radius: 50%;
        border: 1px solid $teal;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        @include Gilroy-Bold;
      }
    }

    .adddivision {
      .a {
        fill: #05b5bc;
      }
      .b {
        fill: none;
        stroke: #fff;
        stroke-width: 2px;
      }
    }
  }

  .section {
    margin: 0;
    &:first-of-type {
      margin-top: 1px;
    }
    &:last-of-type {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      margin-bottom: 1px;

      &:after {
        content: "";
        position: absolute;
        right: 20px;
        left: 20px;
        bottom: 0;
        height: 1px;
        background-color: $fog;
      }
    }

    &.buttons {
      justify-content: space-between;
      &:after {
        background: transparent;
      }
      button {
        width: calc(50% - 12px);
      }
    }

    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-height: 0;
    overflow: hidden;
    padding: 0 15px;
    transition: all ease 0.3s;
    box-sizing: border-box;

    &.add {
      max-height: 200px !important;
      overflow: auto;
    }

    .default-inputfield {
      width: 100px;
    }

    &:after {
      content: "";
      position: absolute;
      right: 20px;
      left: 20px;
      bottom: 0;
      height: 1px;
      background-color: $fog;
    }

    p {
      margin: 0;

      &.info {
        font-size: 14px;
        margin-top: 15px;
        width: 100%;
        color: $strom;
      }

      a {
        color: $teal;
      }
    }
  }

  .delete {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $blizzard;
    padding: 0;
    overflow: hidden;
    max-height: 0;

    &.deletable {
      color: $dusk;
      cursor: pointer;
      font-size: 14px;
    }
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  h6 {
    font-size: 16px;
  }

  .division-editor-container {
    max-width: calc(50% - 20px);

    &.expanded {
      .section {
        p {
          &.info {
            margin-top: 18px;
          }
        }

        .title {
          width: auto;
        }

        .default-inputfield {
          width: 100px;
          margin-left: auto;
        }
        .currency {
          width: 100px;
        }
        .currency-info {
          width: 100px;
          height: 48px;
        }
      }
    }
  }
}
</style>


