var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loaded)?_c('main',{staticClass:"home"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"wrapper"},[_c('section',{staticClass:"details"},[_c('div',{class:{ error: _vm.erronousSections.includes('tourname') },attrs:{"id":"tourname"}},[_c('h5',[_vm._v("Tour name")]),_c('DefaultTextInput',{attrs:{"value":_vm.tour.name,"maxlength":50,"placeholder":"Add tour name (min 3 chars)"},on:{"change":function($event){return _vm.updateName($event)}}})],1)]),_c('section',{staticClass:"tour-type"},[_c('h5',[_vm._v("Tour type")]),_vm._l((_vm.tourTypes),function(type){return _c('DefaultRadioButton',{key:type.type,attrs:{"groupname":'tourtype',"value":_vm.tour.type,"options":{
          title: type.name,
          value: type,
        }},on:{"change":function($event){return _vm.$store.dispatch('updateTourType', type)}}})}),_c('DefaultRadioButton',{attrs:{"groupname":'tourtype',"value":_vm.tour.type,"options":{ title: 'Other', value: null }},on:{"change":function($event){return _vm.$store.dispatch('updateTourType', null)}}})],2),_c('section',{staticClass:"divisions",class:{ error: _vm.erronousSections.includes('divisions') },attrs:{"id":"divisions"}},[_c('h5',[_vm._v("Divisions")]),_c('div',{staticClass:"existing-divisions"},_vm._l((_vm.tour.divisions),function(division){return _c('TheTjingDivisionEditor',{key:division.type,attrs:{"division":division,"tourPassAllowed":true,"usedDivisions":_vm.usedDivisions,"availableDivisions":_vm.availableDivisions,"builderMode":true,"currencies":_vm.currencies},on:{"updateDivision":function($event){return _vm.updateDivision($event)},"deleteDivision":function($event){return _vm.deleteDivision(division.type)}}})}),1),_c('div',{staticClass:"new-divisions"},[_c('TheTjingDivisionEditor',{attrs:{"tourPassAllowed":true,"usedDivisions":_vm.usedDivisions,"availableDivisions":_vm.availableDivisions,"addMode":true,"builderMode":true,"currencies":_vm.currencies},on:{"addDivision":function($event){return _vm.addDivision($event)}}})],1),_c('p',{staticClass:"mt-4 mb-0"},[_vm._v(" Stages and criteria can be added to divisions in the Manager after you have saved your tour. ")])]),_c('section',{staticClass:"leaderboard"},[_c('div',{staticClass:"sub-section left"},[_c('h5',[_vm._v("Leaderboard")]),_c('DefaultRadioButton',{attrs:{"groupname":'scoringType',"value":_vm.tour.scoringType,"options":{
            title: 'Combined results',
            value: 'THROWS',
          }},on:{"change":function($event){return _vm.$store.dispatch('updateScoringType', 'THROWS')}}}),_c('DefaultRadioButton',{attrs:{"groupname":'scoringType',"value":_vm.tour.scoringType,"options":{
            title: 'Average results',
            value: 'AVERAGE_THROWS',
          }},on:{"change":function($event){return _vm.$store.dispatch('updateScoringType', 'AVERAGE_THROWS')}}}),_c('DefaultRadioButton',{attrs:{"groupname":'scoringType',"value":_vm.tour.scoringType,"options":{
            title: 'Combined pars',
            value: 'PAR',
          }},on:{"change":function($event){return _vm.$store.dispatch('updateScoringType', 'PAR')}}}),_c('DefaultRadioButton',{attrs:{"groupname":'scoringType',"value":_vm.tour.scoringType,"options":{
            title: 'Points',
            value: 'POINTS',
          }},on:{"change":function($event){return _vm.$store.dispatch('updateScoringType', 'POINTS')}}})],1),_c('div',{staticClass:"sub-section auto-open"},[_vm._m(2),_c('p',[_vm._v("Set how many events should be counted on the tour leaderboard.")]),_c('div',{staticClass:"item-container"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tour.scoreBasedOnNrOfEvents),expression:"tour.scoreBasedOnNrOfEvents"}],staticClass:"number-of-events",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tour, "scoreBasedOnNrOfEvents", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){_vm.$store.dispatch(
                'updateScoreBasedOnNumberOfEvents',
                parseInt($event.target.value) || null
              )}]}},[_c('option',{domProps:{"value":null}},[_vm._v("All events")]),_vm._l((52),function(n){return (n > 1)?_c('option',{domProps:{"value":n}},[_vm._v(" "+_vm._s(n)+" ")]):_vm._e()})],2)])])]),_c('section',{staticClass:"registration-type"},[_c('h5',[_vm._v("Registration type")]),_c('DefaultRadioButton',{attrs:{"groupname":'regtype',"value":_vm.tour.registrationMethod,"options":{
          title: 'Waiting list',
          value: 'LIST',
          description:
            'Standard registration where players are placed on a waiting list and spots are granted by the TD.',
        }},on:{"change":function($event){return _vm.$store.dispatch('updateRegistrationType', 'LIST')}}}),_c('DefaultRadioButton',{attrs:{"groupname":'regtype',"value":_vm.tour.registrationMethod,"options":{
          title: 'Groups',
          value: 'GROUPS',
          description:
            'A more casual registration where players choose their own group to play in (note: groups can still be adjusted by the TD).',
        }},on:{"change":function($event){return _vm.$store.dispatch('updateRegistrationType', 'GROUPS')}}}),_c('div',{staticClass:"registration-messages"},[_c('b',[_vm._v("Registration notes")]),_c('DefaultToggle',{attrs:{"value":_vm.tour.allowRegistrationMessage},on:{"input":function($event){return _vm.$store.dispatch('updateRegistrationMessage', $event)}}}),_c('p',[_vm._v(" Allows players to include a note when registering for an event. Only event admins can see notes. ")])],1)],1),_c('section',{staticClass:"nav-buttons"},[_c('div',{staticClass:"tip"},[_c('span',[_c('Tip')],1),_c('p',[_vm._v(" Everything can be edited in the Manager after your tour has been saved. ")])]),_c('StandardBorderedButton',{attrs:{"title":"Back","fluid":false,"backwardsIcon":true,"desktopFluidity":true},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('StandardSolidButton',{staticClass:"forwards",attrs:{"title":"Next","fluid":false,"forwardIcon":true,"desktopFluidity":true},on:{"click":function($event){return _vm.validateAndGoNext()}}})],1),_c('section',{staticClass:"start-over",on:{"click":function($event){return _vm.$refs.resetmodal.open()}}},[_vm._v(" Reset and start over ")])]),_c('sweet-modal',{ref:"resetmodal",attrs:{"enable-mobile-fullscreen":false,"blocking":"","hide-close-button":""}},[_c('h3',[_vm._v("Reset")]),_c('p',[_vm._v(" This will remove any information you have added and take you back to the start. ")]),_c('div',{staticClass:"buttons"},[_c('StandardSolidButton',{attrs:{"title":"Cancel","fluid":false},on:{"click":function($event){return _vm.$refs.resetmodal.close()}}}),_c('StandardBorderedButton',{staticClass:"forwards",attrs:{"title":"Reset","fluid":false},on:{"click":function($event){return _vm.resetAndClose('resetmodal')}}})],1)])],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"progress"},[_c('p',{staticClass:"stage"},[_vm._v("1")]),_c('div',{staticClass:"bar"},[_c('span')]),_c('p',{staticClass:"max"},[_vm._v("3")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"header"},[_c('h1',[_vm._v("Tournament details")]),_c('p',[_vm._v("Add and set key details about your tournament (required)")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('h6',[_vm._v("Based on number of best events")])])}]

export { render, staticRenderFns }